















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class RJChip extends Vue {

  /**
   * The content of the chip
   */
  @Prop({required: true})
  public content!: string;

  /**
   * Something that can happen on chip clicked
   */
  @Prop({default: undefined})
  public atClick!: any;

  @Prop({default: 'background'})
  private chipColor!: string;

  @Prop({default: 'on-background-variant'})
  private textColor!: string;


  private showCloseBtn: boolean = false;

  private onRemove() {
    this.$emit('remove');
  }

  private get clickMethod() {
    if (this.atClick) {
      this.showCloseBtn = true;
      return this.atClick;
    }
    return this.onRemove;
  }
}
